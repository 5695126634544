<template>
  <img :src="appLogoImage" style="max-width: 120px" />
</template>

<script>
import { $themeConfig } from "@themeConfig";

export default {
  setup(props) {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
    };
  }
}
</script>